import { apiGetCall, apiPostCall } from 'src/services/api/api.core';
import { EApiEndpoint } from 'src/config/enums';
import { applyValues, getCookie } from 'src/utils';
import type { TAppointmentDate } from 'src/services/api/api.types';
import * as Sentry from '@sentry/gatsby';

type TAppointmentBookingRequest = {
    patientId: string;
    clinicId: number;
    date: string;
    timeOfDay: string;
    contactMethod: string;
    practitionerId: string | null;
    fbcEventId: string | null;
};

export const postAppointmentBookingRequest = async (data: TAppointmentBookingRequest) => {
    const headers = {
        'X-FBP': getCookie('_fbp'),
        'X-FBC': getCookie('_fbc'),
        'X-Ad-Landing-Query': getCookie('utmtracking'),
        'X-Fb-Event-Id': data.fbcEventId
    };

    const captureContext = {
        extra: {
            utmtracking: getCookie('utmtracking'),
            fbp: getCookie('_fbp'),
            fbc: getCookie('_fbc')
        }
    };

    Sentry.captureMessage('utmtrackingConversion', captureContext);

    return await apiPostCall(EApiEndpoint.AppointmentBookingRequest, JSON.stringify(data), {
        credentials: true,
        headers: headers
    });
};

type TAppointmentBookingRequestTimes = {
    clinicId: number;
    appointmentTypeId: string;
};

export const getAppointmentBookingRequestTimes = async (
    params: TAppointmentBookingRequestTimes
): Promise<TAppointmentDate[] | null> => {
    return await apiGetCall(
        applyValues(EApiEndpoint.AppointmentBookingRequestAppointmentTime, params)
    );
};
